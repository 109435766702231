import React from "react";

import { Box } from "rebass/styled-components";
import Main from "../components/main";
import Text from "../components/text";
import { H1, H2, H3 } from "../components/headers";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
`;

const StyledList = styled.ul`
  list-style: disc; 
  margin-left: 16px;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  color: white;
  border: 1px solid #83AEAE;
  margin-bottom: 32px;
`;

const StyledRowHeader = styled.th`
  border: 1px solid #83AEAE;
  padding: 16px;
`;

const StyledTableText = styled.td`
  border: 1px solid #83AEAE;
  padding: 16px;
`;

const CookiePolicy = () => (
  <>
    <SEO title="Cookie policy" />
    <Layout>
    <Main p={[3, 5, 5]}>
      <Box p={4} width={[1, 1/2, 1/2]}>
        <H1 mb={5}>Cookie Policy</H1>
        <H2 mb={3}>Information about our use of cookies</H2>
        <Text color="white" mb={4}>
          Our website uses cookies to distinguish you from other users of our website. 
          This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. 
          By continuing to browse the site, you are agreeing to our use of cookies. 
          A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. 
          Cookies contain information that is transferred to your computer's hard drive.
        </Text>

        <H2 mb={4}>What types of cookies do we use?</H2>
        <H3 mb={3}>Strictly necessary cookies</H3>
        <Text color="white" mb={4}>
          These are cookies that are required for the operation of our website. 
          They include, for example, cookies that enable you to log into secure areas of our website or make use of services.
        </Text>
        <H3 mb={3}>Analytical/performance cookies</H3>
        <Text color="white" mb={4}>
          They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. 
          This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
        </Text>
        <H3 mb={3}>Functionality cookies</H3>
        <Text color="white" mb={4}>
          These are used to recognise you when you return to our website. 
          This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
        </Text>
        <H3 mb={3}>Targeting cookies</H3>
        <Text color="white" mb={4}>
          These cookies record your visit to our website, the pages you have visited and the links you have followed. 
          We will use this information to make our website and the advertising displayed on it more relevant to your interests. 
          We may also share this information with third parties for this purpose. 
        </Text>

        <H2 mb={3}>Individual Cookies</H2>
        <Text color="white" mb={3}>
          You can find more information about the individual cookies we use and the purposes for which we use them in the table below:
        </Text>
          <StyledTable>
            <tr>
              <StyledRowHeader>Name</StyledRowHeader>
              <StyledRowHeader>Provider</StyledRowHeader>
              <StyledRowHeader>Purpose</StyledRowHeader>
              <StyledRowHeader>Type</StyledRowHeader>
            </tr>
            <tr>
              <StyledTableText>_gat</StyledTableText>
              <StyledTableText>managemymeds.co.uk</StyledTableText>
              <StyledTableText>Used by Google Analytics to throttle request rate</StyledTableText>
              <StyledTableText>Analytical</StyledTableText>
            </tr>
            <tr>
              <StyledTableText>_gid</StyledTableText>
              <StyledTableText>managemymeds.co.uk</StyledTableText>
              <StyledTableText>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</StyledTableText>
              <StyledTableText>Analytical</StyledTableText>
            </tr>
            <tr>
              <StyledTableText>_ga</StyledTableText>
              <StyledTableText>managemymeds.co.uk</StyledTableText>
              <StyledTableText>Used to distinguish users</StyledTableText>
              <StyledTableText>Analytical</StyledTableText>
            </tr>
          </StyledTable>

        <H2 mb={3}>Can you block our cookies?</H2>
        <Text color="white" mb={4}>
          Cookies help you to get the most from our website, so please remember that if you do choose to disable cookies, you may find that certain sections of our website do not work properly.
        </Text>

        <H2 mb={3}>How do I disable cookies?</H2>
        <Text color="white" mb={4}>
          All recent versions of popular browsers give you a level of control over cookies. You can find out how to control cookies in your browser by visiting this site: <StyledLink href="www.aboutcookies.org/how-to-control-cookies/">www.aboutcookies.org/how-to-control-cookies/</StyledLink>
        </Text>
        <Text color="white" mb={4}>
          Find out how to manage cookies on popular browsers:
          <StyledList>
            <li><StyledLink href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</StyledLink></li>
            <li><StyledLink href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy">Microsoft Edge</StyledLink></li>
            <li><StyledLink href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences">Mozilla Firefox</StyledLink></li>
            <li><StyledLink href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</StyledLink></li>
            <li><StyledLink href="https://help.opera.com/en/latest/web-preferences/">Opera</StyledLink></li>
            <li><StyledLink href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">Apple Safari</StyledLink></li>
            <li>To find information relating to other browsers, visit the browser developer's website.</li>
            <li>To opt-out of being tracked by Google Analytics across all websites, visit <StyledLink href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</StyledLink></li>
          </StyledList>
        </Text>

        <H2 mb={3}>More information</H2>
        <Text color="white" mb={4}>
          If you'd like to know more about how businesses use cookies visit: <StyledLink href="www.allaboutcookies.org ">www.allaboutcookies.org </StyledLink>
        </Text>
      </Box>
    </Main>
    </Layout>
  </>
);

export default CookiePolicy;
